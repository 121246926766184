import React from "react"
import { graphql } from "gatsby"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const AreaOverview = ({ data }) => {
  // const areasDE = data.areasDE.edges
  // const areasEN = data.areasEN.edges
  // const areasFR = data.areasFR.edges
  // return (
  //   <>
  //     <div className="mt-4 px-grid">
  //       <h1 className="mb-2 text-xl">Bereiche DE:</h1>
  //       <div className="flex flex-wrap">
  //         {areasDE.map((variant) => (
  //           <div className="w-full mb-1">
  //             <Link className="hover:text-red-500" link={variant.node.fullSlug}>
  //               {variant.node.name}
  //             </Link>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //     <div className="mt-4 px-grid">
  //       <h1 className="mb-2 text-xl">Bereiche EN:</h1>
  //       <div className="flex flex-wrap">
  //         {areasEN.map((variant) => (
  //           <div className="w-full mb-1">
  //             <Link className="hover:text-red-500" link={variant.node.fullSlug}>
  //               {variant.node.name}
  //             </Link>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //     <div className="mt-4 px-grid">
  //       <h1 className="mb-2 text-xl">Bereiche FR:</h1>
  //       <div className="flex flex-wrap">
  //         {areasFR.map((variant) => (
  //           <div className="w-full mb-1">
  //             <Link className="hover:text-red-500" link={variant.node.fullSlug}>
  //               {variant.node.name}
  //             </Link>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   </>
  // )
  return <div>area-overview</div>
}

// export const data = graphql`
//   {
//     areasDE: allContentServProductArea(filter: { locale: { eq: "de" } }) {
//       edges {
//         node {
//           name
//           locale
//           fullSlug
//         }
//       }
//     }
//     areasEN: allContentServProductArea(filter: { locale: { eq: "en" } }) {
//       edges {
//         node {
//           name
//           locale
//           fullSlug
//         }
//       }
//     }
//     areasFR: allContentServProductArea(filter: { locale: { eq: "fr" } }) {
//       edges {
//         node {
//           name
//           locale
//           fullSlug
//         }
//       }
//     }
//   }
// `

export default AreaOverview
